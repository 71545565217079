import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import FirstScreen from "../components/BlogSingle/FirstScreen/FirstScreen"
import Content from "../components/BlogSingle/Content/Content"
import Subscription from "../components/HomePage/Subscription/Subscription"
import BlockPress from "../components/AboutPage/InThePress/InThePress"
import LazyHydrate from "react-lazy-hydration"

//Content
import YAMLGlobal from "../content/global.yaml"
import YAMLContent from "../content/homePage.yaml"
import YAMLAbout from "../content/aboutPage.yaml"

const BlogSinglePage = ({ location, data }) => {
  const post = data.post.edges[0].node

  const blog = data.blog.edges

  const subscriptionDescription = data.home.subscription_description
  const subscriptionAttention = data.home.subscription_attention

  return (
    <Layout location={location}>
      <SEO
        title={post.seo_title || post.title}
        description={post.seo_description || post.description}
        keywords={post.seo_keywords}
        image={post.image && post.image.localFile.childImageSharp.fixed.src}
      />
      <LazyHydrate whenIdle>
        <FirstScreen image={post.image} imageAlt={post.image_alt} />
      </LazyHydrate>

      <Content
        category={post.category}
        date={post.created_on}
        title={post.title}
        description={post.description}
        content={post.content}
        share={YAMLGlobal.share}
        linkCopied={YAMLGlobal.linkCopied}
        location={location}
      />
      <BlockPress
        header={YAMLAbout.press.header2}
        button={YAMLGlobal.readMore}
        noCategory={YAMLAbout.press.noCategory}
        data={blog}
      />
      <Subscription
        description={subscriptionDescription}
        attention={subscriptionAttention}
        header={YAMLContent.subscription.header}
        email={YAMLContent.subscription.email}
        button={YAMLGlobal.subscribe}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    post: allDirectusBlogEng(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          category
          created_on(formatString: "MMM DD, YYYY")
          image {
            localFile {
              childImageSharp {
                fixed(quality: 90, fit: COVER, height: 630, width: 1200) {
                  src
                }
                fluid(maxWidth: 1170) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image_alt
          title
          description
          content
          seo_title
          seo_description
          seo_keywords
        }
      }
    }
    home: directusHomeEng {
      subscription_description
      subscription_attention
    }
    blog: allDirectusBlogEng(
      filter: { slug: { ne: $slug } }
      limit: 3
      sort: { fields: created_on, order: DESC }
    ) {
      edges {
        node {
          id
          category
          created_on(formatString: "MMM DD, YYYY")
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 960) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          description
          slug
        }
      }
    }
  }
`

export default BlogSinglePage
