import React, { useCallback, useLayoutEffect, useState } from "react"
import urlSlug from "url-slug"
import cx from "classnames"
import * as s from "./navigation-mobile.module.sass"
import { useCurrentTheme } from "../../../utils/themeChanger"
import ArrowBottom from "../../../icons/light/arrow-bottom-bold.svg"
import ArrowBottomDark from "../../../icons/dark/arrow-bottom-bold.svg"

const Navigation = () => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  const [sections, setSections] = useState(null)
  const [sectionsOpened, setSectionsOpened] = useState(false)

  useLayoutEffect(() => {
    let activeSections = 0
    const contentSections = [...document.querySelectorAll("h2")]
    contentSections.splice(contentSections.length - 2, 2)
    contentSections.forEach(el => {
      el.setAttribute("id", urlSlug(el.innerText))
    })
    const sections = contentSections.map(el => ({
      value: urlSlug(el.innerText),
      label: el.innerText,
    }))

    setSections(sections)

    const callback = function (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (activeSections > 1) {
            ;[...document.querySelectorAll(`.${s.list} li>a`)].forEach(
              element => {
                element.style["font-weight"] = "normal"
              }
            )
          }

          const elementId = urlSlug(entry.target.innerText)

          const element = document.querySelectorAll(`[href="#${elementId}"]`)[0]
          element.style["font-weight"] = "bold"
          activeSections = activeSections + 1
        }
      })
    }

    const observer = new IntersectionObserver(callback, { threshold: 1 })

    contentSections.forEach(el => {
      observer.observe(el)
      el.setAttribute("id", urlSlug(el.innerText))
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  const scrollTo = useCallback(e => {
    e.preventDefault()
    window.scrollBy(
      0,
      document
        .querySelector(e.target.attributes[0].nodeValue)
        .getBoundingClientRect().top - 140
    )
  }, [])

  const toogleSectionsOpened = useCallback(() => {
    setSectionsOpened(oldState => !oldState)
  }, [])

  return (
    <div className={s.container}>
      <div className={s.topBar}>
        <h5 className={s.title}>TABLE OF CONTENTS</h5>
        <button
          onClick={toogleSectionsOpened}
          className={cx(s.openBtn, { [s.openedBtn]: sectionsOpened })}
        >
          <img src={isLightTheme ? ArrowBottom : ArrowBottomDark} />
        </button>
      </div>

      <div className={s.listWrapper}>
        <ul className={cx(s.list, { [s.listOpened]: sectionsOpened })}>
          {sections?.map(({ value, label }) => (
            <li key={value}>
              <a onClick={scrollTo} href={`#${value}`}>
                {label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Navigation
