import React, { useCallback, useLayoutEffect, useState } from "react"
import urlSlug from "url-slug"
import * as s from "./navigation.module.sass"

const Navigation = () => {
  const [sections, setSections] = useState(null)

  useLayoutEffect(() => {
    let activeSections = 0
    const contentSections = [...document.querySelectorAll("h2")]
    contentSections.splice(contentSections.length - 2, 2)
    const sections = contentSections.map(el => ({
      value: urlSlug(el.innerText),
      label: el.innerText,
    }))

    setSections(sections)

    const callback = function (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (activeSections > 1) {
            ;[...document.querySelectorAll(`.${s.list} li>a`)].forEach(
              element => {
                element.style["font-weight"] = "normal "
              }
            )
          }

          const elementId = urlSlug(entry.target.innerText)

          const element = document.querySelectorAll(`[href="#${elementId}"]`)[1]
          element.style["font-weight"] = "bold"
          activeSections = activeSections + 1
        }
      })
    }

    const observer = new IntersectionObserver(callback, { threshold: 1 })

    contentSections.forEach(el => {
      observer.observe(el)
      el.setAttribute("id", urlSlug(el.innerText))
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  const scrollTo = useCallback(e => {
    e.preventDefault()
    window.scrollBy(
      0,
      document
        .querySelector(e.target.attributes[0].nodeValue)
        .getBoundingClientRect().top - 140
    )
  }, [])

  return (
    <div className={s.container}>
      <div className={s.containerInner}>
        <h5>Table of contents:</h5>

        <ul className={s.list}>
          {sections?.map(({ value, label }) => (
            <li key={value}>
              <a onClick={scrollTo} href={`#${value}`}>
                {label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Navigation
