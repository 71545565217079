import React from "react"
import Img from "gatsby-image"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"

// Styles
import * as s from "./first-screen.module.sass"
import StyledCard from "../../../ui-kit/StyledCard/StyledCard"
import Placeholder from "../../../icons/light/placeholder.svg"

const FirstScreen = ({ image, imageAlt }) => {
  return (
    <div className={s.content}>
      <Container>
        <Row>
          <div className={s.icon}>
            {image ? (
              <Img
                alt={imageAlt || "Header image"}
                className={s.iconImage}
                fluid={image.localFile.childImageSharp.fluid}
              />
            ) : (
              <StyledCard>
                <div className={s.placeholderInner}>
                  <img alt={imageAlt || "Header image"} src={Placeholder} />
                </div>
              </StyledCard>
            )}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default FirstScreen
