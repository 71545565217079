import React, { useEffect, useState } from "react"
import cx from "classnames"
import { Link } from "gatsby"
import Tippy from "@tippyjs/react"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import Button from "../../../ui-kit/Button/Button"

// Icons
import Facebook from "../../../icons/light/blog/Facebook.svg"
import Linkedin from "../../../icons/light/blog/Linkedin.svg"
import Twitter from "../../../icons/light/blog/Twitter.svg"
import Telegram from "../../../icons/light/blog/Telegram.svg"

import FacebookDark from "../../../icons/light/blog/FacebookDark.svg"
import LinkedinDark from "../../../icons/light/blog/LinkedinDark.svg"
import TwitterDark from "../../../icons/light/blog/TwitterDark.svg"
import TelegramDark from "../../../icons/light/blog/TelegramDark.svg"

import LinkIcon from "../../../icons/light/blog/Link.svg"
import LinkIconDark from "../../../icons/dark/blog/Link.svg"

import { useCurrentTheme } from "../../../utils/themeChanger"
import LazyHydrate from "react-lazy-hydration"
// Styles
import * as s from "./content.module.sass"
import Navigation from "../Navigation/Navigation"
import NavigationMobile from "../NavigationMobile/NavigationMobile"

const Content = ({
  location,
  category,
  date,
  title,
  description,
  content,
  share,
  linkCopied,
}) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  const [currentPage, setCurrentPage] = useState("")

  useEffect(() => {
    setCurrentPage(location && location.href ? location.href : "")
  }, [])

  const [shownCopied, setShownCopied] = useState({
    top: false,
    bottom: false,
  })

  const copyToClipboard = str => {
    const el = document.createElement("textarea")
    el.value = str
    el.setAttribute("readonly", "")
    el.style.position = "absolute"
    el.style.left = "-9999px"
    document.body.appendChild(el)
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)
  }

  return (
    <Container size="small">
      <LazyHydrate whenIdle>
        <NavigationMobile />
      </LazyHydrate>

      <Row>
        <article className={s.inner}>
          <LazyHydrate ssrOnly>
            <header>
              <div className={s.categoryDate}>
                {category && (
                  <Link to="/blog">
                    <span className={s.category}>{category}</span>
                  </Link>
                )}
                <span className={s.date}>{date}</span>
              </div>
              <h1 className={s.header}>{title}</h1>
            </header>
          </LazyHydrate>
          <div className={s.contentParent}>
            <div className={s.socialsMain}>
              <div className={s.socialsMainInner}>
                <div className={s.socialsMainButton}>
                  <Button
                    link={`https://www.facebook.com/sharer/sharer.php?u=${currentPage}`}
                    external
                    theme="secondary small"
                  >
                    <img
                      alt="Facebook"
                      src={isLightTheme ? FacebookDark : Facebook}
                    />
                  </Button>
                </div>
                <div className={s.socialsMainButton}>
                  <Button
                    link={`https://www.linkedin.com/shareArticle?mini=true&url=${currentPage}`}
                    external
                    theme="secondary small"
                  >
                    <img
                      alt="LinkedIn"
                      src={isLightTheme ? LinkedinDark : Linkedin}
                    />
                  </Button>
                </div>
                <div className={s.socialsMainButton}>
                  <Button
                    link={`https://twitter.com/intent/tweet?url=${currentPage}&text=${description}`}
                    external
                    theme="secondary small"
                  >
                    <img
                      alt="Twitter"
                      src={isLightTheme ? TwitterDark : Twitter}
                    />
                  </Button>
                </div>
                <div className={s.socialsMainButton}>
                  <Button
                    link={`https://t.me/share/url?url=${currentPage}&text=${description}`}
                    external
                    theme="secondary small"
                  >
                    <img
                      alt="Telegram"
                      src={isLightTheme ? TelegramDark : Telegram}
                    />
                  </Button>
                </div>
                <div
                  className={cx(s.socialsMainButton, s.socialsMainButtonLink)}
                >
                  <Tippy
                    content={linkCopied}
                    placement="bottom"
                    arrow={false}
                    className={s.tippy}
                    onShow={instance => {
                      setTimeout(() => {
                        instance.hide()
                      }, 1000)
                    }}
                    visible={shownCopied.top}
                    zIndex={900}
                  >
                    <Button
                      onClick={() => {
                        copyToClipboard(currentPage)
                        setShownCopied({ top: true, bottom: false })
                      }}
                      theme="secondary small"
                    >
                      <img
                        src={isLightTheme ? LinkIcon : LinkIconDark}
                        alt="copy link"
                      />
                    </Button>
                  </Tippy>
                </div>
              </div>
            </div>
            <LazyHydrate ssrOnly>
              <p className={s.description}>{description}</p>
              <div
                className={s.content}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </LazyHydrate>
            <LazyHydrate whenIdle>
              <Navigation />
            </LazyHydrate>
          </div>
          <footer className={s.footer}>
            <LazyHydrate ssrOnly>
              <h4 className={s.footerShare}>{share}</h4>
            </LazyHydrate>
            <LazyHydrate ssrOnly>
              <div className={s.footerButtons}>
                <div className={s.footerButtonWrapper}>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${currentPage}`}
                    target="_blank"
                    rel="noreferrer nofollow"
                    className={cx(s.footerButton, s.footerFacebook)}
                  >
                    <div className={s.footerContent}>
                      <img alt="Facebook" src={Facebook} />
                      <span className={s.footerButtonLabel}>Facebook</span>
                    </div>
                  </a>
                </div>
                <div className={s.footerButtonWrapper}>
                  <a
                    className={cx(s.footerButton, s.footerLinkedin)}
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentPage}`}
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    <div className={s.footerContent}>
                      <img alt="LinkedIn" src={Linkedin} />
                      <span className={s.footerButtonLabel}>LinkedIn</span>
                    </div>
                  </a>
                </div>
                <div className={s.footerButtonWrapper}>
                  <a
                    className={cx(s.footerButton, s.footerTwitter)}
                    href={`https://twitter.com/intent/tweet?url=${currentPage}&text=${description}`}
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    <div className={s.footerContent}>
                      <img alt="Twitter" src={Twitter} />
                      <span className={s.footerButtonLabel}>Twitter</span>
                    </div>
                  </a>
                </div>
                <div className={s.footerButtonWrapper}>
                  <a
                    className={cx(s.footerButton, s.footerTelegram)}
                    href={`https://t.me/share/url?url=${currentPage}&text=${description}`}
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    <div className={s.footerContent}>
                      <img alt="Telegram" src={Telegram} />
                      <span className={s.footerButtonLabel}>Telegram</span>
                    </div>
                  </a>
                </div>
              </div>
            </LazyHydrate>
            <div className={s.footerLink}>
              <Tippy
                content={linkCopied}
                placement="bottom"
                arrow={false}
                className={s.tippy}
                onShow={instance => {
                  setTimeout(() => {
                    instance.hide()
                  }, 1000)
                }}
                visible={shownCopied.bottom}
              >
                <Button
                  theme="secondary small"
                  onClick={() => {
                    copyToClipboard(currentPage)
                    setShownCopied({ top: false, bottom: true })
                  }}
                >
                  <img
                    src={isLightTheme ? LinkIcon : LinkIconDark}
                    alt="copy link"
                  />
                </Button>
              </Tippy>
            </div>
          </footer>
        </article>
      </Row>
    </Container>
  )
}

export default Content
