// extracted by mini-css-extract-plugin
export var category = "content-module--category--A5sPo";
export var categoryDate = "content-module--categoryDate--8chbe";
export var content = "content-module--content--YQVgI";
export var contentParent = "content-module--contentParent--K13nA";
export var date = "content-module--date--HpZ08";
export var description = "content-module--description--7Vblp";
export var footer = "content-module--footer--iBxyO";
export var footerButton = "content-module--footerButton--Rb79B";
export var footerButtonLabel = "content-module--footerButtonLabel--sQYUx";
export var footerButtonWrapper = "content-module--footerButtonWrapper--4hica";
export var footerButtons = "content-module--footerButtons--lwpMR";
export var footerContent = "content-module--footerContent--GabBM";
export var footerFacebook = "content-module--footerFacebook--l0RP2";
export var footerLink = "content-module--footerLink--X2Z1X";
export var footerLinkedin = "content-module--footerLinkedin--+gfTr";
export var footerShare = "content-module--footerShare--V7wl1";
export var footerTelegram = "content-module--footerTelegram--gAjPI";
export var footerTwitter = "content-module--footerTwitter--0+hCP";
export var header = "content-module--header---u+tX";
export var inner = "content-module--inner--tIcOo";
export var socialsMain = "content-module--socialsMain--oOsmP";
export var socialsMainButton = "content-module--socialsMainButton--aMCLC";
export var socialsMainButtonLink = "content-module--socialsMainButtonLink--EcQiO";
export var socialsMainInner = "content-module--socialsMainInner---JgSI";
export var tippy = "content-module--tippy--DBIzy";